@charset "UTF-8";
@import url("./Carousel");
.desktopIcon {
  height: fit-content;
  width: fit-content;
  font-size: 0.9rem;
}
.desktopIcon .icon {
  display: flex;
  height: fit-content;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 11ch;
  text-align: center;
  padding: 5px;
  border: 2px solid rgba(255, 255, 255, 0);
  transition: all 0.1s linear;
  color: #fff;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3764705882);
  gap: 5px;
}
.desktopIcon .icon:hover,
.desktopIcon .icon * {
  cursor: url("../assets/sys/pointer.png"), pointer;
}
.desktopIcon .icon img {
  width: 40px;
  max-height: 40px;
  object-fit: contain;
}
.desktopIcon .icon:hover {
  background-color: rgba(255, 255, 255, 0.3137254902);
  border: 2px solid rgba(255, 255, 255, 0.5019607843);
}

.sender {
  margin-left: auto;
}
@media screen and (max-width: 630px) {
  .sender {
    margin-left: unset;
  }
}

.desktop {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 630px) {
  .desktop {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    justify-items: center;
    align-items: center;
  }
}

/******* Window *******/
.window {
  background-color: #ede8d7;
  box-sizing: border-box;
  overflow: hidden;
  border-bottom: 5px solid #1e62cd;
  position: absolute;
  transition: all 0.15s linear;
  flex-direction: column;
  z-index: 1;
}
.window .controls {
  background-color: #1e62cd;
  box-shadow: 0px 0px 10px #98b7e3 inset;
  box-sizing: border-box;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.window .windTitle {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
}
.window .windTitle img {
  height: 25px;
  width: auto;
}
.window .windTitle h3 {
  margin: 0;
  color: #fff;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resize {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 13px 13px 0px 0px;
  min-width: 220px;
  min-height: 200px;
  max-height: 95dvh;
  max-width: 95lvw;
  width: 60%;
  height: 70%;
}
@media screen and (max-width: 768px) {
  .resize {
    width: 80%;
    height: 80%;
  }
}

.windBody {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-left: 5px solid #1e62cd;
  border-right: 5px solid #1e62cd;
  overflow-y: auto;
}

.embed {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #525659;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.embed a {
  background-color: #f2f2f0;
  box-shadow: 0px 0px 4px #1e62cd inset;
  padding: 10px 20px;
  border-radius: 7px;
  color: #000;
  text-decoration: none;
}
.embed a:focus, .embed a:hover {
  box-shadow: 0px 0px 4px #ffa500 inset;
}
.embed iframe {
  display: none;
}

.fullscreen {
  width: 100lvw;
  height: calc(100dvh - 40px);
  transform: none;
  top: 0;
  left: 0;
  border-radius: 0;
}
.fullscreen > .controls .size {
  background-image: url("../assets/sys/small.svg");
  background-size: 15px 15px;
}

.controlCont {
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.controlButton {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 7px #fff inset;
  background-position: center;
  background-repeat: no-repeat;
}
.controlButton:hover {
  cursor: url("../assets/sys/pointer.png"), pointer;
}

.red {
  background-color: #f00;
  background-image: url("../assets/sys/close.svg");
}

.mini {
  background-image: url("../assets/sys/mini.svg");
  background-position: -1px -2px;
}

.size {
  background-image: url("../assets/sys/large.svg");
  background-size: 20px 20px;
}

.post {
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  padding: 10px;
}

.post .postInfo,
.post .postDetails {
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 7px;
  min-height: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.postInfo {
  width: 40%;
  display: flex;
  flex-direction: column;
  max-width: 576px;
}
.postInfo .shortPar {
  margin: 0.2rem 0px;
}
.postInfo .paragraphs {
  margin: 0.5rem 0px 1rem;
}
.postInfo .thumb {
  width: 100%;
  border-radius: 7px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2509803922);
  margin-bottom: 0.8rem;
}
.postInfo .parentF {
  align-self: center;
  width: 60%;
  max-width: 90px;
  margin-bottom: 12px;
}
.postInfo .post_title {
  margin: 0;
}
.postInfo .social_links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.postInfo p {
  font-size: 0.8rem;
}
.postInfo .social {
  width: 25px;
  cursor: url("../assets/sys/pointer.png"), pointer;
  transition: all 0.15s linear;
}
.postInfo .social:hover {
  transform: scale(1.2);
}

.postDetails {
  width: 100%;
}
.postDetails .detailTitle {
  margin: 0 0 0.313rem;
  color: #45b042;
}
.postDetails p {
  font-size: 0.85rem;
  line-height: 1.2rem;
}
.postDetails .skills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.postDetails .anchor {
  color: #1e62cd;
  cursor: url("../assets/sys/pointer.png"), pointer;
}
.postDetails > h3:not(:first-child) {
  margin-top: 10px;
}
.postDetails .tech {
  width: 40px;
}
.postDetails .li {
  padding-left: 0.7rem;
}
.postDetails .li::before {
  content: "▪ ";
}
.postDetails .bold {
  font-weight: 600;
}
.postDetails .nest {
  padding-left: 1rem;
}
.postDetails .nest::before {
  content: "• ";
}
.postDetails .heading {
  font-weight: bold;
  font-size: 1rem !important;
  margin-top: 1rem;
  color: #45b042;
}

.parentPost .post_title {
  text-align: center;
}

.postDetails.postIcons .collection {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 100px);
  justify-items: center;
}
.postDetails.postIcons .collection .icon {
  color: #000;
  text-shadow: unset;
}

.detailP {
  margin: 5px 0px;
}

.nestedIcon.fullscreen {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 520px) {
  .windBody {
    flex-direction: column;
  }
  .windBody .postInfo,
  .windBody .postDetails {
    width: 100%;
    min-height: unset;
  }
}
.forward {
  z-index: 5;
}

