#Contact {
  flex-grow: 1;
}

form {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
form label {
  margin-bottom: 5px;
}
form input:not([type=submit]),
form textarea {
  margin-bottom: 15px;
  padding: 7px;
  cursor: text;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 2px #000 inset;
}
form input:not([type=submit]):focus,
form textarea:focus {
  box-shadow: 0px 0px 2px #1e62cd inset;
  outline: none;
}
form input:not([type=submit]):user-invalid,
form textarea:user-invalid {
  box-shadow: 0px 0px 2px #f00 inset;
}
form textarea {
  flex-grow: 1;
  resize: vertical;
}
form .button {
  margin-top: auto;
  padding: 5px 20px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: url("../assets/sys/pointer.png"), pointer;
  background-color: #f2f2f0;
  border: none;
  box-shadow: 0px 0px 4px #1e62cd inset;
  border-radius: 4px;
  margin-left: auto;
  transition: all 0.15s linear;
  color: #000;
}
form .button:focus, form .button:hover {
  outline: none;
  box-shadow: 0px 0px 4px #ffa500 inset;
}/*# sourceMappingURL=contact.css.map */