@use "./vars" as *;

::-webkit-scrollbar {
  width: 11px;
}
::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #bccaf7;
  cursor: $pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8baf6;
}

* {
  cursor: $cursor;
  font-family: Arial, "Book Antiqua", "Times Roman";
  scroll-behavior: smooth;
}

body {
  background: url("../assets/backg.jpg") no-repeat center;
  background-size: cover;
  width: 100lvw;
  height: 100dvh;
  margin: 0;
  padding: 0;
  position: relative;
}

.App {
  position: relative;
  width: 100lvw;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

audio {
  display: none;
}

.unseen {
  display: none !important;
}

.unseen:checked + label + .window {
  display: flex;
}

.unseen:not(:checked) + label + .window {
  display: none;
}
