header {
  background-image: linear-gradient(#1e62cd, transparent);
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 10px;
  color: #fff;
  text-shadow: 0px 0px 0.2rem #000;
}
header h2 {
  margin: 0;
  font-size: 1.2rem;
}
header .dateTime {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  text-align: center;
}
header .dateTime * {
  white-space: nowrap;
}/*# sourceMappingURL=header.css.map */