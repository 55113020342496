#Calculator.resize {
  height: -moz-max-content !important;
  height: max-content !important;
  min-height: unset !important;
}

.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  box-sizing: border-box;
}

.inputGrid {
  margin-top: 20px;
  padding: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(40px, 1fr));
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
}

#myCalc {
  width: 100%;
  padding: 10px;
  text-align: right;
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 0px 2px #000 inset;
  box-sizing: border-box;
  max-width: 500px;
  background-color: #fff;
}

.calcB {
  cursor: url("../assets/sys/pointer.png"), pointer;
  border: none;
  box-shadow: 0px 0px 3px #000 inset;
  padding: 10px;
  border-radius: 4px;
  color: #1e62cd;
  transition: all 0.15s linear;
  background-color: #f3f3ee;
  outline: none;
}
.calcB:nth-child(1) {
  grid-column: 1/3;
}
.calcB:nth-child(2) {
  grid-column: 3/5;
}
.calcB:nth-child(3) {
  grid-column: 5/7;
}
.calcB:hover {
  box-shadow: 0px 0px 5px #ffa500 inset;
}

.redText {
  color: #f00;
}/*# sourceMappingURL=calculator.css.map */