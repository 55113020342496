@use "./vars" as *;

.loadScreen {
  background-color: #00309c;
  width: 100lvw;
  height: 100dvh;
  display: flex;
  align-items: center;
}

.loadBody {
  width: 100%;
  height: 85%;
  background-color: #5a7edc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  @include inShadow($backg, 10px);
  box-sizing: border-box;
  padding: 20px 10px;

  .owner {
    width: 200px;
    border-radius: 10px;
    border: 3px solid #92b3ef;
  }

  input {
    border: none;
    outline: none;
    padding: 7px;
    border-radius: 5px;
    height: 100%;
    box-sizing: border-box;
    max-height: 35px;
    cursor: text;
  }

  button {
    background-color: $greenP;
    @include inShadow($greenS, 15px);
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 7px;
    padding: 5px;
    cursor: $pointer;

    &:hover {
      background-color: $greenBord;
    }

    &:hover * {
      cursor: $pointer;
    }
  }

  .userName {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
}
