$prim: #1e62cd;
$sec: #98b7e3;
$backg: #ede8d7;
$orange: #ffa500;
$greenP: #45b042;
$greenS: #34883b;
$greenBord: #72cf6f;
$footerH: 40px;
$windBorder: 5px solid $prim;
$cursor: url("../assets/sys/cursor.png"), auto;
$pointer: url("../assets/sys/pointer.png"), pointer;
$radius: 4px;
$phone: 480px;
$break: 640px;
$tab: 700px;
$normal: 1100px;
$test: rgb(35, 175, 200);
$highlight: #006a9b;

@mixin inShadow($color, $blur) {
  box-shadow: 0px 0px $blur $color inset;
}

@mixin go_to($dir) {
  animation: $dir 0.75s infinite reverse linear;
}
