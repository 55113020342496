@use "./vars" as *;

footer {
  background-color: $prim;
  @include inShadow($sec, 10px);
  position: relative;
  margin-top: auto;
  display: flex;
  align-items: center;
  width: 100lvw;
  height: $footerH;
  gap: 5px;
}

.current_open{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #003bfd;
    cursor: $pointer;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #a8baf6;
  }
}

.footerLabel {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: $radius;
  gap: 7px;
  color: #fff;
  background-color: $prim;
  height: 100%;
  @include inShadow($sec, 15px);
  transition: all 0.15s linear;
  width: clamp(25px, 100%, 220px);
  overflow: hidden;
  min-width: 30px;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }

  img {
    height: 30px;
  }

  &:hover,
  &:hover * {
    cursor: $pointer;
  }

  &:hover {
    background-color: $sec;
    cursor: $pointer;
  }
}

#startM:checked + label + .startMenu {
  display: flex;
  flex-direction: column;
}

.start {
  background-color: $greenP;
  height: 100%;
  width: fit-content;
  min-width: 50px;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 0px 15px 0px 10px;
  gap: 7px;
  @include inShadow($greenS, 15px);
  border-radius: 0 $radius $radius 0;

  &:hover {
    background-color: $greenBord;
    cursor: $pointer;
  }
}

.start img {
  filter: drop-shadow(1px 0px 1px black);
  height: 50% !important;
}

.startMenu {
  width: auto;
  min-width: 250px;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: $backg;
  display: none;
  border-radius: 11px 11px 0px 0px;
  overflow: hidden;
  z-index: 6;

  .currUser {
    background-color: $prim;
    height: fit-content;
    padding: 10px;
    margin: 0;
    @include inShadow($sec, 5px);
    color: #fff;
    font-weight: 500;
  }

  .menuBody {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.power {
  background-color: $prim;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 7px 5px;
  gap: 7px;
}

.machineCont {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  border-radius: $radius;
  border: 1px solid #fff;
  cursor: $pointer;
  transition: all 0.15s linear;
}

.logout {
  background-image: url("../assets/sys/key.svg");
  background-color: #f4dd6f;
  @include inShadow(#e3b859, 10px);

  &:hover {
    background-color: #ecec00;
  }
}

.powerOff {
  background-image: url("../assets/sys/off.svg");
  background-color: #d6460a;
  @include inShadow(#e99174, 7px);

  &:hover {
    background-color: #f00;
  }
}

.startMenuButton {
  height: fit-content;
  padding: 10px 5px;
  flex-grow: 1 0;
  width: auto;
  border-radius: 0;
  color: #000;
  background-color: transparent;
  @include inShadow(#000, 0px);
}
