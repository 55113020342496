footer {
  background-color: #1e62cd;
  box-shadow: 0px 0px 10px #98b7e3 inset;
  position: relative;
  margin-top: auto;
  display: flex;
  align-items: center;
  width: 100lvw;
  height: 40px;
  gap: 5px;
}

.current_open {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  overflow-x: auto;
}
.current_open::-webkit-scrollbar {
  height: 1px;
}
.current_open::-webkit-scrollbar-track {
  background: #fff;
}
.current_open::-webkit-scrollbar-thumb {
  background: #003bfd;
  cursor: url("../assets/sys/pointer.png"), pointer;
}
.current_open::-webkit-scrollbar-thumb:hover {
  background: #a8baf6;
}

.footerLabel {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 4px;
  gap: 7px;
  color: #fff;
  background-color: #1e62cd;
  height: 100%;
  box-shadow: 0px 0px 15px #98b7e3 inset;
  transition: all 0.15s linear;
  width: clamp(25px, 100%, 220px);
  overflow: hidden;
  min-width: 30px;
}
.footerLabel p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
.footerLabel img {
  height: 30px;
}
.footerLabel:hover, .footerLabel:hover * {
  cursor: url("../assets/sys/pointer.png"), pointer;
}
.footerLabel:hover {
  background-color: #98b7e3;
  cursor: url("../assets/sys/pointer.png"), pointer;
}

#startM:checked + label + .startMenu {
  display: flex;
  flex-direction: column;
}

.start {
  background-color: #45b042;
  height: 100%;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 50px;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 0px 15px 0px 10px;
  gap: 7px;
  box-shadow: 0px 0px 15px #34883b inset;
  border-radius: 0 4px 4px 0;
}
.start:hover {
  background-color: #72cf6f;
  cursor: url("../assets/sys/pointer.png"), pointer;
}

.start img {
  filter: drop-shadow(1px 0px 1px black);
  height: 50% !important;
}

.startMenu {
  width: auto;
  min-width: 250px;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #ede8d7;
  display: none;
  border-radius: 11px 11px 0px 0px;
  overflow: hidden;
  z-index: 6;
}
.startMenu .currUser {
  background-color: #1e62cd;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  margin: 0;
  box-shadow: 0px 0px 5px #98b7e3 inset;
  color: #fff;
  font-weight: 500;
}
.startMenu .menuBody {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.power {
  background-color: #1e62cd;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 7px 5px;
  gap: 7px;
}

.machineCont {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  border-radius: 4px;
  border: 1px solid #fff;
  cursor: url("../assets/sys/pointer.png"), pointer;
  transition: all 0.15s linear;
}

.logout {
  background-image: url("../assets/sys/key.svg");
  background-color: #f4dd6f;
  box-shadow: 0px 0px 10px #e3b859 inset;
}
.logout:hover {
  background-color: #ecec00;
}

.powerOff {
  background-image: url("../assets/sys/off.svg");
  background-color: #d6460a;
  box-shadow: 0px 0px 7px #e99174 inset;
}
.powerOff:hover {
  background-color: #f00;
}

.startMenuButton {
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 5px;
  flex-grow: 1 0;
  width: auto;
  border-radius: 0;
  color: #000;
  background-color: transparent;
  box-shadow: 0px 0px 0px #000 inset;
}/*# sourceMappingURL=footer.css.map */