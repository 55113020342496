.loadScreen {
  background-color: #00309c;
  width: 100lvw;
  height: 100dvh;
  display: flex;
  align-items: center;
}

.loadBody {
  width: 100%;
  height: 85%;
  background-color: #5a7edc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  box-shadow: 0px 0px 10px #ede8d7 inset;
  box-sizing: border-box;
  padding: 20px 10px;
}
.loadBody .owner {
  width: 200px;
  border-radius: 10px;
  border: 3px solid #92b3ef;
}
.loadBody input {
  border: none;
  outline: none;
  padding: 7px;
  border-radius: 5px;
  height: 100%;
  box-sizing: border-box;
  max-height: 35px;
  cursor: text;
}
.loadBody button {
  background-color: #45b042;
  box-shadow: 0px 0px 15px #34883b inset;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 7px;
  padding: 5px;
  cursor: url("../assets/sys/pointer.png"), pointer;
}
.loadBody button:hover {
  background-color: #72cf6f;
}
.loadBody button:hover * {
  cursor: url("../assets/sys/pointer.png"), pointer;
}
.loadBody .userName {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}/*# sourceMappingURL=load.css.map */