@use './vars' as *;

#Contact{
    flex-grow: 1;
}

form {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  label {
    margin-bottom: 5px;
  }

  input:not([type="submit"]),
  textarea {
    margin-bottom: 15px;
    padding: 7px;
    cursor: text;
    border: none;
    border-radius: $radius;
    @include inShadow(#000, 2px);
  }

  input:not([type="submit"]):focus,
  textarea:focus {
    @include inShadow($prim, 2px);
    outline: none;
  }

  input:not([type="submit"]):user-invalid,
  textarea:user-invalid {
    @include inShadow(#f00, 2px);
  }

  textarea {
    flex-grow: 1;
    resize: vertical;
  }

  .button {
    margin-top: auto;
    padding: 5px 20px;
    width: fit-content;
    cursor: $pointer;
    background-color: #f2f2f0;
    border: none;
    @include inShadow($prim, 4px);
    border-radius: $radius;
    margin-left: auto;
    transition: all .15s linear;
    color: #000;
    
    &:focus, &:hover{
        outline: none;
        @include inShadow($orange, 4px);
    }
  }
}
