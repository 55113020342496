@use "./vars" as *;

#Calculator.resize {
  height: max-content !important;
  min-height: unset !important;
}

.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  box-sizing: border-box;
}

.inputGrid {
  margin-top: 20px;
  padding: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(40px, 1fr));
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
}

#myCalc {
  width: 100%;
  padding: 10px;
  text-align: right;
  border: none;
  outline: none;
  border-radius: $radius;
  @include inShadow(#000, 2px);
  box-sizing: border-box;
  max-width: 500px;
  background-color: #fff;
}

.calcB {
  cursor: $pointer;
  border: none;
  @include inShadow(#000, 3px);
  padding: 10px;
  border-radius: $radius;
  color: $prim;
  transition: all 0.15s linear;
  background-color: #f3f3ee;
  outline: none;

  &:nth-child(1) {
    grid-column: 1/3;
  }

  &:nth-child(2) {
    grid-column: 3/5;
  }

  &:nth-child(3) {
    grid-column: 5/7;
  }

  &:hover {
    @include inShadow($orange, 5px);
  }
}

.redText {
  color: #f00;
}
