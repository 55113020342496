@use "./vars" as *;
@import url("./Carousel");

.desktopIcon {
  height: fit-content;
  width: fit-content;
  font-size: 0.9rem;

  .icon {
    display: flex;
    height: fit-content;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-width: 11ch;
    text-align: center;
    padding: 5px;
    border: 2px solid #ffffff00;
    transition: all 0.1s linear;
    color: #fff;
    text-shadow: 0px 0px 2px #00000060;
    gap: 5px;

    &:hover,
    * {
      cursor: $pointer;
    }

    img {
      width: 40px;
      max-height: 40px;
      object-fit: contain;
    }
  }

  .icon:hover {
    background-color: #ffffff50;
    border: 2px solid #ffffff80;
  }
}

.sender {
  margin-left: auto;
  @media screen and (max-width: 630px) {
    margin-left: unset;
  }
}

.desktop {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 630px) {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    justify-items: center;
    align-items: center;
  }
}

/******* Window *******/

.window {
  background-color: $backg;
  box-sizing: border-box;
  overflow: hidden;
  border-bottom: $windBorder;
  position: absolute;
  transition: all 0.15s linear;
  flex-direction: column;
  z-index: 1;

  .controls {
    background-color: $prim;
    @include inShadow($sec, 10px);
    box-sizing: border-box;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .windTitle {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;

    img {
      height: 25px;
      width: auto;
    }

    h3 {
      margin: 0;
      color: #fff;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.resize {
  // resize: both;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 13px 13px 0px 0px;
  min-width: 220px;
  min-height: 200px;
  max-height: 95dvh;
  max-width: 95lvw;
  width: 60%;
  height: 70%;

  @media screen and (max-width: 768px) {
    width: 80%;
    height: 80%;
  }
}

.windBody {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-left: $windBorder;
  border-right: $windBorder;
  overflow-y: auto;
}

.embed {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #525659;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a{
    background-color: #f2f2f0;
    @include inShadow($prim, 4px);
    padding: 10px 20px;
    border-radius: 7px;
    color: #000;
    text-decoration: none;
    
    &:focus, &:hover{
        @include inShadow($orange, 4px);
    }
  }

  iframe {
    display: none;
  }
}

.fullscreen {
  width: 100lvw;
  height: calc(100dvh - $footerH);
  transform: none;
  top: 0;
  left: 0;
  border-radius: 0;

  & > .controls .size {
    background-image: url("../assets/sys/small.svg");
    background-size: 15px 15px;
  }
}

.controlCont {
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.controlButton {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 5px;
  @include inShadow(#fff, 7px);
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: $pointer;
  }
}

.red {
  background-color: #f00;
  background-image: url("../assets/sys/close.svg");
}

.mini {
  background-image: url("../assets/sys/mini.svg");
  background-position: -1px -2px;
}

.size {
  background-image: url("../assets/sys/large.svg");
  background-size: 20px 20px;
}

.post {
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  padding: 10px;
}

.post .postInfo,
.post .postDetails {
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 7px;
  min-height: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.postInfo {
  width: 40%;
  display: flex;
  flex-direction: column;
  max-width: calc(1440px * 40 / 100);

  .shortPar {
    margin: 0.2rem 0px;
  }

  .paragraphs {
    margin: 0.5rem 0px 1rem;
  }

  .thumb {
    width: 100%;
    border-radius: 7px;
    box-shadow: 3px 3px 5px #00000040;
    margin-bottom: 0.8rem;
  }

  .parentF {
    align-self: center;
    width: 60%;
    max-width: 90px;
    margin-bottom: 12px;
  }

  .post_title {
    margin: 0;
  }

  .social_links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  p {
    font-size: 0.8rem;
  }

  .social {
    width: 25px;
    cursor: $pointer;
    transition: all 0.15s linear;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.postDetails {
  width: 100%;

  .detailTitle {
    margin: 0 0 .313rem;
    color: $greenP;
  }

  p {
    font-size: 0.85rem;
    line-height: 1.2rem;
  }

  .skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }

  .anchor{
    color: $prim;
    cursor: $pointer;
  }

  & > h3:not(:first-child) {
    margin-top: 10px;
  }

  .tech {
    width: 40px;
  }

  .li {
    padding-left: 0.7rem;
    
    &::before {
      content: "▪ ";
    }
  }
  
  .bold {
    font-weight: 600;
  }

  .nest {
    padding-left: 1rem;

    &::before {
      content: "• ";
    }
  }
  
  .heading {
    font-weight: bold;
    font-size: 1rem !important;
    margin-top: 1rem;
    color: $greenP;
  }
}

.parentPost {
  .post_title {
    text-align: center;
  }
}

.postDetails.postIcons .collection {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 100px);
  justify-items: center;

  .icon {
    color: #000;
    text-shadow: unset;
  }
}

.detailP {
  margin: 5px 0px;
}

.nestedIcon.fullscreen {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 520px) {
  .windBody {
    flex-direction: column;

    .postInfo,
    .postDetails {
      width: 100%;
      min-height: unset;
    }
  }
}

.forward {
  z-index: 5;
}